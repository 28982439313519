.story-comment-container{
    position: fixed;
    right:0.2%;
    top: 12%;
    /* min-width: 20.3vw;
    max-width: 20.3vw; */
    width:20.1vw;
    height: 80%;
    /* background-color: aqua; */
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    overflow-y: auto;
    z-index: 1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    /* transform: 5s; */
}

.story-comment-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.story-comment-container .replies{
    color: black;
    padding-left: 6%;
    margin-right:1%;
}

.story-comment-container .repliesHeaderContainer{
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 1;
    background: rgb(255,255,255);
}
.story-comment-container .repliesHeader{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8%;
    
}


.story-comment-container .addComment{
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-right:2%;
}
.story-comment-container .addComment textarea{
    height: 20px;
    width: 100%;
    margin-right: 2%;
    margin-left: 2%;
    border: none;
    border-bottom: 2px solid #aaa;
    background-color: transparent;
    resize: none;
    outline:none;

}

.story-singlecomment{
    display:flex;
    margin-top: 2%;
    width:100%;
    margin-right: 4%;
        
}

.story-singleCommentContent{
    margin-left:3%;
    word-break:break-word;
    padding-bottom:5%;
}
.story-singleCommentContent p{
    font-family:'Roboto';
    line-height: 17px;
    width: 90%;
    font-weight: 300;
    margin: 0;
}

@media (max-width:1200px){
    .story-comment-container{
        width:99vw;
        top:30vh;
        height: 70vh;
        
        
    }
    
}