.single-story{
    /* display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 2px;
    padding: 5px;
    /* padding-bottom: 10px; */
    /* transition: box-shadow .3s; */ 
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
    border-radius: 2px;
    padding: 8px 5px;
    /* padding-bottom: 10px; */
    transition: box-shadow .3s
}

.story-author{
    margin-top:-5px;
}
.single-story:hover{
    cursor:pointer;
    background-color: #ededed
    /* box-shadow: 0 0 11px rgba(33,  */

}

.story-dp{
    /* height: 50px;
    width: 50px;
    position: relative;
    border-radius: 50%;
    margin-right: 15px;
    padding: 3px; */
    height: 45px;
    width: 45px;
    position: relative;
    border-radius: 50%;
    margin-right: 6px;
    padding: 3px;
}

.story-dp img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.story-author p.name{
    /* margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 13px; */
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
    color: black;
}
.story-author p.time{
    margin-top: 5px;
    margin-bottom: 0px;
    line-height: 0;
    font-weight: 500;
    font-size: 12px;
}