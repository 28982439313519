.view-story{
    position: fixed;
    top: 12%;
    /* right: 0; */
    left: 26.7vw;
    width: 60.8VW;
    height: 80%;
    background-color: #fff;
    opacity: 1;
}

.view-story-image-div{
    position: relative;
    width: 99.5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    background: rgb(0,0,0,1);
    z-index: -1;
}
.view-story-image-div:hover{
    cursor: pointer;
}
.view-story-top-content{
    position: absolute;
    top: 1.1rem;
    left: 1rem;
    width: 96.8%;
    display: flex;
    align-items: center;
    background-color: rgb(0,0,0,0.05);
    color: white;
}
.shrink{
    width: 52.5vw;
}
.view-story img{
   
   max-width: 70%;
   max-height: 100%;
   object-fit: contain;
}

.progress-bar{
    height: 4px;
    width: 90%;
    left:5%;
    position: absolute;
    top: 10px;
    border-radius: 3px;
    background-color: black;
    /* opacity: 0; */
}

.progress-maker{
    height: 2px;
    width:2%;
    background-color: #fff;
}

.cross-btn{
    position: absolute;
    right: 0;
    padding:0.8rem;
    padding-top: 0;

}

.cross-btn a:hover{
    color:inherit;
}


.Navigator .forwardArrow{
    position: absolute;
    top: 0;
    right: 10%;
    display: flex;
    /* align-items: center; */
    height: 100%;
    
}
.Navigator .forwardArrow button{
    outline: none;
    position: absolute;
    top:40%;
}


.Navigator .backwardArrow{
    position: absolute;
    top: 0;
    left: 10%;
    display: flex;
    /* align-items: center; */
    height: 100%;
    
}

.Navigator .backwardArrow button{
    outline:none;
    position: absolute;
    top:40%;
}

.not-found-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: hideAnimation 0s ease-in 2s;
    animation-fill-mode: forwards;
    visibility: hidden;
    
    
  }
  @keyframes hideAnimation{
      to{
        visibility:visible;
      }
  }
  .not-found-wrapper h1 {
    font-size: 3rem;
    
  }
  .not-found-wrapper .message {
    font-size: 1.5rem;
    padding: 20px;
    text-align: center;
    width: 60%;
    
  }


@media (max-width:1200px){
    .view-story{
        width: 100%;
        left:0;
        height: 88%;
    }

    .view-story-image-div{
        height: 100%;
        margin-top: 0;
        width: 100%;
    }

    .view-story img{
        max-width: 70%;
    }

    .Navigator .backwardArrow{
        left: 7%;
    }

    .Navigator .forwardArrow{
        right: 15%;
    }
}