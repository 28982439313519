.App{
    width: 100vw;
    height: 87vh;
    display: flex;
    
  }
  
  
.App  a{
    text-decoration: none;
    color: inherit;
  }
  
.App h2{
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.App h3{
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.App p{
  /* display: block; */
  /* margin-top: 1em; */
  /* margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  display: block; */
    /* margin-top: 1em; */
    margin-bottom: 11px;
    font-weight: 400;
    margin-top: 11px;
    margin-left: 0;
    margin-right: 0;
}

.App strong{
  font-weight:600;
}
  
.App button{
  all:unset;
  cursor: pointer;
  outline: none;
  border:none;
  background: none;

}

.App button:focus{
  outline:none;
  box-shadow: none;
}
::-webkit-scrollbar-thumb {
  background: none;
}

::-webkit-scrollbar-track {
  /* background-color: #ebebeb; */
  background: none;
}

.no-hover{
  pointer-events: none;
  cursor:default;
}

.no-story-view-message{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 12%;
    left: 26.7vw;
    width: 60.8VW;
    height: 80%;
    background-color: #FFFFFF;
    opacity: 1;
    color:#a3a7a8;
}

.no-story-view-message img{
  display: block;
}
@media (max-width:1200px){
  .no-story-view-message{
    display: none;
  }
}