.InteractBar{
    position: absolute;
    display: flex;
    right: 0;
    flex-direction: column;
    bottom: 0;
    /* background-color: rgb(0,0,0,0.2); */
    color: white;
    justify-content:space-around;
    height: 45%;
    padding-right: 3%;
    padding-bottom: 6%;
}

.InteractBar a:hover{
    color:inherit;
}

.InteractBar button{
    outline:none;
    
}


.make-center{
    display: flex;
    justify-content: center;
}

.like-active{
    color:#019fe2;
}

@media (max-height:800px){
    .InteractBar{
        
        height: 52%;
    }

}